import React from 'react';
import { Link } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';

import logo from '../img/aw-black.svg'
import styles from './Header.module.css';

class Header extends React.Component {
    render(){
        return <header>
            <div className={ styles['lang-bar'] }>
                {/*<div className="wrapper">
                    <span>FR</span>
                </div>*/}
            </div>
            <nav className={ styles.navigation }>
                <div className="wrapper">
                    <Link to="/"><img src={ logo } alt="Logo" className={ styles.logo } /></Link>
                    <HeaderMenu />
                </div>
            </nav>
        </header>
    }
}

export default Header;

/**
 * Convert an array of objects to an object map.
 * @param {Object[]} array The array to convert to an object.
 * @param {string} keyProperty The property to use as a key in the object for each item in the array.
 */
const arrayToObject = (array, keyProperty) => {
    // Using an empty object as an accumulator, we fill it with its key/value
    return array.reduce((object, item) => {
        object[item[keyProperty]] = item
        return object;
    }, {});
};

/**
 * Add an index to each object in the array.
 * @param {Object[]} array The array to add the index to each object.
 */
const addIndex = (array) => 
    array.map((item, index) => {
        item.index = index;
        return item;
    });

// Promise that will contain a list of all the photography projects.
const photoListPromises = fetch(`${ process.env.PUBLIC_URL }/data/photography.json`)
    .then(response => response.json())
    .then(addIndex);

// Promise that will contain a map of all photography projects by URL.
const photoMapPromises = photoListPromises
    .then((projects) => arrayToObject(projects, 'url'));

// Promise that will contain a list of all the design projects.
const designListPromises = fetch(`${ process.env.PUBLIC_URL }/data/design.json`)
    .then(response => response.json())
    .then(addIndex);

// Promise that will contain a map of all design projects by URL.
const designMapPromises = designListPromises
    .then((projects) => arrayToObject(projects, 'url'));

export { photoListPromises, photoMapPromises, designListPromises, designMapPromises };
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { photoMapPromises, designMapPromises } from '../data';

export default function ProjectListProvider(props) {
    // Project list in component state
    const [project, setProject] = useState(null);

    // Get project ID from URL
    const { projectURL } = useParams();

    // Change fetched data depending on the type
    if(props.type === 'photography'){
        photoMapPromises.then((projects) => setProject(projects[projectURL]));
    }
    else if(props.type === 'design'){
        designMapPromises.then((projects) => setProject(projects[projectURL]));
    }

    // Pass every props, but not children
    let propsToPass = { ...props };
    delete propsToPass.children;

    return <props.children.type { ...props.children.props } { ...propsToPass } project={ project } />
};


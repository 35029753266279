import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import audreyWilkie from '../img/audrey-wilkie.jpg';
import styles from './About.module.css';

class About extends React.Component {
    render(){
        return <>
            <Helmet>
                <title>Audrey Wilkie | About</title>
                <meta name="description" content="Portfolio of Audrey Wilkie" />
                <meta name="keywords" content="Portfolio, About"/>
            </Helmet>

            <div className={ styles.banner }>
                <div className={ styles.info + ' wrapper' }>
                    <img src={ audreyWilkie } alt="Audrey Wilkie" className="img-border" />
                    <div className={ styles.text }>
                        <h1>Hi! Bonjour!</h1>
                        <p>I’m Audrey, a graphic designer/photographer/artist. Woah, a photographer in this day and age? Yep. I love it and I just cannot hide it. I have been taking photos since I was 7. It started with single-use cameras, then it was to college to study, and now it is a full-blown addiction – it was my first love. I bring my camera everywhere. Frankly, I am a shy person but having a camera in between me and people makes me get to know them on a deeper level. I love that experience!</p>
                        <p>I feel like I am putting graphic design on the backburner in this short bio. It was my second love (don’t tell my husband). I went to school for graphic design after photography. Life is hard out of school, so I wanted to go back to learn more. This time, I thought long and hard and settled on graphic design. It was like a duck to water. I started working after that in the industry and I have been there for a few years.</p> 
                        <p>If you read that entire bio, thank you! If you are looking at my work and enjoy it, check out my packages here, or simply email me at <a href="mailto:info@audreywilkie.com">info@aurdreywilkie.com</a>.</p>
                    </div>
                </div>
            </div>

            <section className={ styles.service }>
                <div className="wrapper">
                    <h2>Invest in Your Experience</h2>
                    <p>
                    I would love to work with you. I want to get to know you and understand how my services fit in. Do you need a logo? Okay, lets talk about your ideas and lets make that a reality. If you want an illustration done, I am your gal. If you… you get it. 
If you need photography or graphic design services, I can help. I want to make sure that your ideas and needs come to life.
                    </p>
                    <Link to="/inquire" className="btn">Learn More</Link>
                </div>
            </section>
        </>
    }
}

export default About;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import inquire1 from '../img/inquire1.jpg';
import inquire2 from '../img/inquire2.jpg';
import styles from './Inquire.module.css';

class About extends React.Component {
    render(){
        return <>
            <Helmet>
                <title>Audrey Wilkie | Inquire</title>
                <meta name="description" content="Inquire Audrey Wilkie" />
                <meta name="keywords" content="Portfolio, Inquire"/>
            </Helmet>

            <div className={ styles.banner }>
                <div className="wrapper">
                    <img src={ inquire1 } alt="Branding example" className="img-border" />
                    <div className={ styles.info }>
                        <h1>Graphic Design Needs</h1>
                        <p className={ styles.description }>
                        If you are looking for a Logo, Branding, Design Guidelines, Illustrations, or for any other Graphic Design projects, look no further. Okay, I sound cheesy there, but I want to work with you! went to school for  graphic design and have been working in the field for last few years. </p>
                        <p className={ styles.description }>If you are interested in my work, please check out my Graphic Design page. If you want to work with me, shoot me an email at <a href="mailto:info@audreywilkie.com">info@audreywilkie.com</a>. Hope to hear from you soon!
                        </p>
                        {/*<button className={ 'btn ' + styles.inquire }>Inquire Now</button>*/}
                    </div>
                </div>
            </div>

            <section className={ styles.service }>
                <div className="wrapper">
                    <div>
                        <h2>Photography Packages</h2>
                        <p className={ styles.description }>
                        If you read the description above, you know I am cheesy. What is cheesier than capturing the genuine moments into little nostalgia packets? I am talking about photos. I love working with people and getting to know their story and take pictures for them. I mostly do portrait photography, but you can see from my Photography page that I do more than that. </p>
                        <p className={ styles.description }>I want to get to know you! Shoot me an email at <a href="mailto:info@audreywilkie.com">info@aurdreywilkie.com</a> if you want to hire me or have any questions. See you on the email thread!
                        </p>
                        {/*<button className={ 'btn ' + styles.inquire }>Inquire Now</button>*/}
                    </div>
                    <img src={ inquire2 } alt="Photography example" className="img-border" />
                </div>
            </section>

            <Accordion allowZeroExpanded={ true } className={ styles.accordion }>
                <div className="wrapper">
                    <AccordionItem className={ styles.item }>
                        <AccordionItemHeading>
                            <AccordionItemButton className={ styles.trigger }>
                                <h3>Wedding + Elopement</h3>
                                <i className="material-icons">
                                    <AccordionItemState>
                                        { (state) => state.expanded ? 'expand_less' : 'expand_more' }
                                    </AccordionItemState>
                                </i>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className= { styles.panel }>
                            <p>
                            Are you looking for something a little different than what I have below? Send me an email and we can set up a custom plan.
                            </p>
                            <ul className={ styles.cards }>
                                <li className={ styles.card }>
                                    <div className={ styles.title }>Basic</div>
                                    <div className={ styles.detail }>6 hours</div>
                                    <div className={ styles.detail }>Starting at 1000$</div>
                                </li>
                                <li className={ styles.card }>
                                    <div className={ styles.title }>Extended</div>
                                    <div className={ styles.detail }>9 hours</div>
                                    <div className={ styles.detail }>Starting at 1500$</div>
                                </li>
                                <li className={ styles.card }>
                                    <div className={ styles.title }>Full</div>
                                    <div className={ styles.detail }>12 hours</div>
                                    <div className={ styles.detail }>Starting at 1900$</div>
                                </li>
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className={ styles.item }>
                        <AccordionItemHeading>
                            <AccordionItemButton className={ styles.trigger }>
                                <h3>Couple + Engagement</h3>
                                <i className="material-icons">
                                    <AccordionItemState>
                                        { (state) => state.expanded ? 'expand_less' : 'expand_more' }
                                    </AccordionItemState>
                                </i>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className= { styles.panel }>
                            <p>
                            Are you looking for something a little different than what I have below? Send me an email and we can set up a custom plan.
                            </p>
                            <ul className={ styles.cards }>
                                <li className={ styles.card }>
                                    <div className={ styles.title }>Couple</div>
                                    <div className={ styles.detail }>2 hours</div>
                                    <div className={ styles.detail }>Starting at 400$</div>
                                </li>
                                <li className={ styles.card }>
                                    <div className={ styles.title }>Engagement</div>
                                    <div className={ styles.detail }>2 hours</div>
                                    <div className={ styles.detail }>Starting at 450$</div>
                                </li>
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className={ styles.item }>
                        <AccordionItemHeading>
                            <AccordionItemButton className={ styles.trigger }>
                                <h3>Family</h3>
                                <i className="material-icons">
                                    <AccordionItemState>
                                        { (state) => state.expanded ? 'expand_less' : 'expand_more' }
                                    </AccordionItemState>
                                </i>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className= { styles.panel }>
                            <p>
                            Family packages starting at $300 for 1 hour.
                            </p>
                            <p>
                            Are you looking for something a little different? Send me an email and we can set up a custom plan.
                            </p>
                        
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className={ styles.item }>
                        <AccordionItemHeading>
                            <AccordionItemButton className={ styles.trigger }>
                                <h3>Newborn + Baby</h3>
                                <i className="material-icons">
                                    <AccordionItemState>
                                        { (state) => state.expanded ? 'expand_less' : 'expand_more' }
                                    </AccordionItemState>
                                </i>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className= { styles.panel }>
                        <p>
                            Newborn and baby session starting at $300 for 2 hour.
                            </p>
                            <p>
                            Are you looking for something a little different? Send me an email and we can set up a custom plan.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className={ styles.item }>
                        <AccordionItemHeading>
                            <AccordionItemButton className={ styles.trigger }>
                                <h3>Products</h3>
                                <i className="material-icons">
                                    <AccordionItemState>
                                        { (state) => state.expanded ? 'expand_less' : 'expand_more' }
                                    </AccordionItemState>
                                </i>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className= { styles.panel }>
                        <p>
                        Every products and companies are different. Send me an email with details about your products and we can set up a custom plan.
                        </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className={ styles.item }>
                        <AccordionItemHeading>
                            <AccordionItemButton className={ styles.trigger }>
                                <h3>Other</h3>
                                <i className="material-icons">
                                    <AccordionItemState>
                                        { (state) => state.expanded ? 'expand_less' : 'expand_more' }
                                    </AccordionItemState>
                                </i>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className= { styles.panel }>
                            <p>
                                Are you looking for something different? Send me an email so we can discuss what type of photo session you want!
                            </p>
                            <p>
                                I also have a many photos available for purchase on <a href="https://fineartamerica.com/profiles/audrey-wilkie" target="_blank" rel="noopener noreferrer">Fine Art America</a>.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </div>
            </Accordion>
        </>
    }
}

export default About;

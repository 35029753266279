import React, { useState } from 'react';
import { photoListPromises, designListPromises } from '../data';

export default function ProjectListProvider(props) {
    // Project list in component state
    const [projectList, setProjectList] = useState(null);

    // Change fetched data depending on the type
    if(props.type === 'photography'){
        photoListPromises.then((projects) => setProjectList(projects));
    }
    else if(props.type === 'design'){
        designListPromises.then((projects) => setProjectList(projects));
    }

    // Pass every props, but not children
    let propsToPass = { ...props };
    delete propsToPass.children;

    return <props.children.type { ...props.children.props } { ...propsToPass } projects={ projectList } />
};

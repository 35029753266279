import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import styles from './ProjectList.module.css';

function ProjectList(props) {
    const currentPath = useLocation().pathname;
    const imgPath = `${ process.env.PUBLIC_URL }/img/${ props.type }`;

    return <>
        <Helmet>
            { props.type === 'photography' ? <>
                <title>Audrey Wilkie | Photography</title>
                <meta name="description" content="Photography projects made by Audrey Wilkie" />
                <meta name="keywords" content="Photography, Projects"/>
            </> : props.type === 'design' ? <>
                <title>Audrey Wilkie | Design</title>
                <meta name="description" content="Design projects made by Audrey Wilkie" />
                <meta name="keywords" content="Design, Projects"/>
            </> : <>
            </> }
        </Helmet>

        <div className="accent-banner"></div>
        { props.projects &&
            <div className={ styles.list }>
                <div className="wrapper">
                    { props.projects.map((project) => (
                        <Link to={ `${ currentPath }/${ project.url }` } key={ project.url } className={ styles.card + ' img-border' }>
                            <div className={ styles.ratio }>
                                <img src={ imgPath + project.couverture } alt={ project.title } />
                            </div>
                            <h1>{ project.title }</h1>
                            <p>{ project.descriptionCourte }</p>
                        </Link>
                    )) }
                </div>
            </div>
        }
    </>
}

export default ProjectList;

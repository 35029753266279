import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import Header from './components/Header'
import Main from './components/Main';
import Footer from './components/Footer';

import 'aos/dist/aos.css';
import './App.css';

export default function App() {
    useEffect(() => {
        AOS.init();
    });

    return <React.StrictMode>
        <HelmetProvider>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <ScrollToTop />
                <Header></Header>
                <Main></Main>
                <Footer></Footer>
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>
};

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import About from './About';
import Inquire from './Inquire';
import ProjectList from './ProjectList';
import PhotoProject from './PhotoProject';
import DesignProject from './DesignProject';
import ProjectProvider from './ProjectProvider';
import ProjectListProvider from './ProjectListProvider';

import './Main.module.css';

function Main() {    
    return <main>
        <Switch>
            <Route exact path="/">
                <About />
            </Route>
            <Route exact path="/photography">
                <ProjectListProvider type="photography">
                    <ProjectList />
                </ProjectListProvider>
            </Route>
            <Route exact path="/photography/:projectURL">
                <ProjectListProvider type="photography">
                    <ProjectProvider>
                        <PhotoProject />
                    </ProjectProvider>
                </ProjectListProvider>
            </Route>
            <Route exact path="/design">
                <ProjectListProvider type="design">
                    <ProjectList />
                </ProjectListProvider>
            </Route>
            <Route exact path="/design/:projectURL">
                <ProjectListProvider type="design">
                    <ProjectProvider>
                        <DesignProject />
                    </ProjectProvider>
                </ProjectListProvider>
            </Route>
            <Route exact path="/inquire">
                <Inquire />
            </Route>
            <Redirect to="/" />
        </Switch>
    </main>
}

export default Main;

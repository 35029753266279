import React, { useEffect } from 'react';
import { Link }  from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AOS from 'aos';

import styles from './DesignProject.module.css'

export default function DesignProject(props) {
    const imgPath = `${ process.env.PUBLIC_URL }/img/${ props.type }`;

    useEffect(() => {
        // We add a delay to ensure that every image is rendered
        setTimeout(AOS.refresh, 200);
    });

    return <>
        { props.project && <>
            <Helmet>
                <title>Audrey Wilkie | Design | { props.project.title }</title>
                <meta name="description" content={ props.project.descriptionCourte } />
            </Helmet>
            
            <div className={ styles.banner }>
                <div className="wrapper">
                    <h1>{ props.project.title }</h1>
                    <p className={ styles.info }>
                        { props.project.typeProjet } { props.project.client && `| ${ props.project.client }` }
                    </p>
                    <p className={ styles.description }>{ props.project.description }</p>
                </div>
            </div>
            
            <div className={ styles.gallery }>
                <div className="wrapper">
                    { props.project.images.map((picture, i) => 
                        <div key={ i } className={ styles.picture }>
                            { picture.endsWith('.mp4') ? 
                                <video src={ imgPath + picture } controls show data-aos="fade-up" data-aos-duration="800"></video>
                            :
                                <img src={ imgPath + picture } alt={ props.project.title + ' ' + i } data-aos="fade-up" data-aos-duration="800" />
                            }
                        </div>
                    )}
                </div>
            </div>
            
            { props.projects && 
                <div className={ styles.navigation }>
                    <Link to={ `/${ props.type }/${ props.projects[(props.project.index - 1 + props.projects.length) % props.projects.length].url }` } className="btn">Previous</Link>
                    <Link to={ `/${ props.type }` } className="btn">Back to Gallery</Link>
                    <Link to={ `/${ props.type }/${ props.projects[(props.project.index + 1) % props.projects.length].url }` } className="btn">Next</Link>
                </div> 
            }
        </> }
    </>
};

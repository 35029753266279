import React from 'react';
import { NavLink } from 'react-router-dom'

import styles from './HeaderMenu.module.css';

export default class HeaderMenu extends React.Component {
    constructor(props){
        super(props);
        this.state = { opened: false }
    }

    toggleMenu = () => {
        this.setState((state) => ({ opened: !state.opened }));
        document.body.classList.toggle('locked');
    }

    closeMenu = () => {
        if(this.state.opened){
            this.toggleMenu();
        }
    }

    render() {
        return <>
            <button className={ styles.button } onClick={ this.toggleMenu }>
                <i className="material-icons">{ ( this.state.opened ? "clear" : "menu" ) }</i>
            </button>
            <ul className={ styles.menu + ' ' + (this.state.opened ? styles.open : '') }>
                <li>
                    <NavLink to="/" exact activeClassName={ styles.active } onClick={ this.closeMenu }>
                        About
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/photography" activeClassName={ styles.active } onClick={ this.closeMenu }>
                        Photography
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/design" activeClassName={ styles.active } onClick={ this.closeMenu }>
                        Design
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/inquire" exact activeClassName={ styles.active } onClick={ this.closeMenu }>
                        Inquire
                    </NavLink>
                </li>
                <li className={ styles.message }>
                    <button className="btn" onClick={ this.closeMenu }>Message Me</button>
                </li>
            </ul>
            <div className={ styles.overlay } onClick={ this.closeMenu }></div>
        </>
    }
}

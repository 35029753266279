import React from 'react';

import styles from './Footer.module.css';

class Footer extends React.Component {
    render(){
        return <footer>
            <div className="wrapper">
                <div className={ styles.copyright }>&copy; Audrey Wilkie 2020</div>
                <div className={ styles.social }>
                    <a href="https://www.instagram.com/audrey.wilkie" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 288"><path d="M256.91,193.09c-.88,18.23-5,34.4-18.38,47.74s-29.51,17.5-47.74,18.38c-15.72.9-31.29.75-47,.75s-31.28.15-47-.75c-18.23-.88-34.4-5-47.74-18.38s-17.5-29.51-18.38-47.74c-.9-15.72-.75-31.29-.75-47s-.15-31.28.75-47c.88-18.23,5-34.4,18.38-47.74S78.55,33.84,96.78,33c15.72-.9,31.29-.75,47-.75s31.29-.15,47,.75c18.23.88,34.4,5,47.74,18.38S256,80.85,256.91,99.08c.9,15.72.75,31.29.75,47S257.81,177.37,256.91,193.09ZM76.61,57.26A39,39,0,0,0,55,78.91c-5.92,15-4.59,50.57-4.59,67.17S49,198.28,55,213.26a39,39,0,0,0,21.65,21.65c15,5.92,50.56,4.59,67.17,4.59s52.2,1.33,67.18-4.59a39,39,0,0,0,21.65-21.65c5.92-15,4.59-50.57,4.59-67.18s1.33-52.19-4.59-67.17A39,39,0,0,0,211,57.26c-15-5.92-50.56-4.59-67.18-4.59S91.59,51.34,76.61,57.26ZM143.78,204.5a58.42,58.42,0,1,1,58.42-58.42A58.33,58.33,0,0,1,143.78,204.5Zm0-96.37a38,38,0,1,0,38,38A38,38,0,0,0,143.78,108.13Zm60.8-9.2a13.64,13.64,0,1,1,13.64-13.64A13.59,13.59,0,0,1,204.58,98.93Z"/></svg>
                    </a>
                    <a href="https://www.pinterest.ca/audreywilkie_photography" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 288"><path d="M149.47,32.2c42.88,0,81.94,29.5,81.94,74.71,0,42.48-21.71,89.6-70.07,89.6-11.47,0-25.94-5.74-31.55-16.39-10.37,41.11-9.55,47.26-32.5,78.67l-1.91.68-1.23-1.36c-.83-8.6-2.05-17.07-2.05-25.68,0-27.86,12.83-68.15,19.13-95.19-3.42-7-4.39-15.44-4.39-23.09,0-13.79,9.57-31.27,25.14-31.27,11.47,0,17.62,8.74,17.62,19.52,0,17.76-12,34.43-12,51.63,0,11.75,9.7,19.94,21,19.94,31.41,0,41.1-45.35,41.1-69.52,0-32.36-22.95-50-53.95-50-36.05,0-63.91,26-63.91,62.56,0,17.62,10.79,26.63,10.79,30.87,0,3.54-2.6,16.12-7.11,16.12a13.65,13.65,0,0,1-2.32-.42c-19.53-5.86-26.63-32-26.63-49.85C56.59,63.34,102.75,32.2,149.47,32.2Z"/></svg>
                    </a>
                    <a href="https://ca.linkedin.com/in/audrey-wilkie-40b0a1121" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 288"><path d="M57.56,86h-.29C40.84,86,30.33,74.74,30.33,60.67c0-14.35,11-25.31,27.53-25.31,16.73,0,27.08,11,27.23,25.31C85.25,74.74,74.59,86,57.56,86ZM82,252.64H33.15V106H82Zm175.68,0H209V174.2c0-19.69-7.11-33.16-24.72-33.16-13.47,0-21.47,9-25,17.76a36.83,36.83,0,0,0-1.62,12v81.85H108.93c.59-132.91,0-146.68,0-146.68h48.69v21.32h-.3c6.37-10.07,17.91-24.72,44.26-24.72,32.12,0,56.09,21,56.09,66Z"/></svg>
                    </a>
                    <a href="https://fineartamerica.com/profiles/audrey-wilkie" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 288"><path d="M209,113.67c6.35.37,13-1.75,19.84-4,19.26-6.44,39.22,2.64,46.91,20.94,7.76,18.49-.22,41-17.63,49.81-18,9.07-38.34,3.63-48.75-14.7-8.12-14.3-19.62-24.28-33.87-31.12-17.69-8.47-30.69-.93-33.71,18.33-2,12.58,1.15,24.18,5.22,36,10.59,30.64-3.35,64.21-32.53,75.56C79.5,278,48.68,265.2,34.17,231.71,20.78,200.79,37,165,69.24,153.08c11.59-4.28,23.08-8.24,32.62-16.52,15-13,16.23-21.91,4.78-37.69-2.22-3.06-4.85-5.82-7-8.9-13.36-18.73-10.49-43.18,6.85-59,15-13.71,37.4-12.66,55.45.33,16.8,12.1,19.36,27.54,15.81,46C173.2,100.67,184.31,113.83,209,113.67Z"/></svg>
                    </a>
                </div>
            </div>
        </footer>
    }
}

export default Footer;
